import React, { Suspense, useState } from 'react';
import Routes from './Routes';
import { ThemeProvider } from './utils/theme';
import { CssBaseline } from '@material-ui/core';
import { PermissionContext } from './contexts/PermissionContext';
import { AuthProviderWrapper } from 'unity-fluent-library';
import { GqlApiProvider } from './api/client';
import { storeInitialPath } from './utils/redirect/LocalRedirectUrlStorage';
import { storeInitialTenant } from './utils/storage/UnitySessionStorage';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

let App = () => {
  const [permissionOptions] = useState({
    level: 'silver',
    option: true,
    mapOption: true,
    mapEditOption: true,
    mapViewOption: true,
  });
  storeInitialPath();
  storeInitialTenant();

  return (
    <Suspense fallback="loading">
      <ThemeProvider>
        <AuthProviderWrapper>
          <PermissionContext.Provider value={permissionOptions}>
            <GqlApiProvider>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <Routes />
              </MuiPickersUtilsProvider>
            </GqlApiProvider>
          </PermissionContext.Provider>
        </AuthProviderWrapper>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
