import {
  Button,
  CardContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserAvatar from '../utils/UserAvatar';
import { storeTenant } from '../utils/storage/UnitySessionStorage';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cardHeader: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  currentTenant: {
    margin: 'auto',
    width: '85%',
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  basicInfoContainer: {
    marginLeft: theme.spacing(1),
  },
  basicInfo: {
    paddingLeft: theme.spacing(1),
  },
  divider: {
    height: theme.spacing(3),
  },
  avatar: {
    fontSize: 40,
  },
}));

export const FluentProfileMenuCard = props => {
  const classes = useStyles();
  const devUnityUrl = 'https://unity-dev.univerus.com/';
  const {
    userTenants,
    currentTenant,
    user,
    setUser,
    logout,
    unityUrl = devUnityUrl,
    displayProfileButton = true,
  } = props;
  const [currentTenantValue, setCurrentTenantValue] = useState({});
  const history = useHistory();
  const redirectToHome = () => {
    const path = window.location.pathname;

    ['providers', 'runs', 'sprints', 'schedules'].forEach(route => {
      if (path.indexOf(route) > -1) {
        history.push(`/${route}`);
      }
    });
  };

  useEffect(() => {
    if (userTenants) {
      const currentUserTenant = userTenants.find(
        ut => ut.tenantId === currentTenant?.tenantId
      );
      setCurrentTenantValue(currentUserTenant);
    }
  }, [currentTenant, userTenants]);

  if (!user) return null;

  const handleAutocompleteOnChange = (newValue, reason) => {
    if (reason !== 'select-option') return;
    setCurrentTenantValue(newValue);
    storeTenant(newValue.tenantId);
    setUser({ ...user, currentTenantId: newValue.tenantId });
    redirectToHome();
  };

  return (
    <CardContent className={classes.root}>
      <div className={classes.cardHeader}>
        <UserAvatar
          name={user?.name}
          email={user?.email}
          size="large"
          className={classes.avatar}
        />
        <div className={classes.basicInfoContainer}>
          <Typography className={classes.basicInfo} variant="subtitle2">
            {user?.name}
          </Typography>
          <Typography className={classes.basicInfo} variant="body2">
            {user?.email}
          </Typography>
          {displayProfileButton && (
            <div className={classes.buttonGroup}>
              <Button
                href={unityUrl + '/profile'}
                target="_blank"
                color="secondary"
              >
                My Profile
              </Button>
            </div>
          )}
        </div>
      </div>
      {userTenants && user && setUser && (
        <Autocomplete
          className={classes.currentTenant}
          value={currentTenantValue}
          disableClearable
          id="current-tenant"
          name="currentTenant"
          options={userTenants ? userTenants : []}
          getOptionSelected={(option, value) => {
            if (value) {
              return option.id === value.id;
            } else {
              return false;
            }
          }}
          getOptionLabel={option =>
            option?.tenantName ? option?.tenantName : ''
          }
          onChange={(event, newValue, reason) =>
            handleAutocompleteOnChange(newValue, reason)
          }
          renderInput={params => (
            <TextField {...params} label="Current Tenant" variant="standard" />
          )}
        />
      )}
      <Button
        onClick={() => {
          logout();
        }}
        variant="outlined"
        color="secondary"
      >
        Sign-out
      </Button>
    </CardContent>
  );
};
