import React, { lazy, Suspense, useEffect, useState, useMemo } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { 
  Shell, 
  useAxiosGet, 
  useUser, 
  useIsUnityAuthenticated,
  LoadingIndicator,
  useUserActions,
} from 'unity-fluent-library';
import { SnackbarProvider } from 'notistack';
import PageLoading from './UI/routing/PageLoading';
import { mockMenus } from './mockData/mockData';
import UserWrapper from './appBarContent/UserWrapper';
import AppBarControls from './appBarContent/AppBarControls';
import AadCallback from './routes/callback/AadCallback';
import { useTheme } from '@material-ui/core';
import PrivateRoute from './routes/PrivateRoute';
import ZeroTenantAccessDialog from './UI/routing/ZeroTenantAccessDialog';
import { getStoredTenant } from './utils/storage/UnitySessionStorage';
import ErrorBoundary from './ErrorBoundary';

const PageNotFound = lazy(() => import('./UI/routing/PageNotFoundRoute.js'));
const LocalLogin = lazy(() => import('./routes/auth/LocalLogin'));
const Logout = lazy(() => import('./routes/Logout'));
const Entries = lazy(() => import('./routes/entries/Entries'));
const Summary = lazy(() => import('./routes/summary/Summary'));

const APP_VERTICAL_ID = 2; // Manage Menus
const APP_TENANT_ID = 'F86A9B7C-BAB0-4281-89A2-52B4CAF89FAB'; // Univerus
const IS_LOCAL_AUTH = process.env.REACT_APP_LOCAL_AUTH === 'true';

const Routes = () => {
  const user = useUser();
  const theme = useTheme();
  const isAuthenticated = useIsUnityAuthenticated();
  const { setUser } = useUserActions();
  const [renderTenant, setRenderTenant] = useState(null);
  const [zeroTenantAccess, setZeroTenantAccess] = useState(false);

  const useMockData = process.env.REACT_APP_USE_MOCKS === 'true';

  // The Starter Kit uses the constants APP_VERICAL_ID, APP_TENANT_ID, and
  // APP_PRODUCT_ID to directly access Unity APIs. Ultimately, these constants
  // should be removed and the API URLs should be changed to hit your product's
  // backend instance, where the constants should instead be stored.

  const [{ data: leftMenu }] = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `menus?menuTypeId=1&productId=${process.env.REACT_APP_UNITY_PRODUCT_ID}&tenantId=${user?.currentTenantId}`,
    {},
    !!!user?.currentTenantId
  );

  const [{ data: appSelectorMenus }] = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `menus?tenantId=${APP_TENANT_ID}&verticalId=${APP_VERTICAL_ID}&userId=${
      user ? user?.id : ''
    }`,
    {},
    !!!user?.id
  );

  const [{ data: currentTenant, error: getTenantError }, refetchCurrentTenant] =
    useAxiosGet(
      process.env.REACT_APP_TENANTS_API_BASE,
      `tenants/${user?.currentTenantId || ''}`,
      {},
      true
    );

  const [{ data: userTenants }] = useAxiosGet(
    process.env.REACT_APP_SECURITY_API_BASE,
    `users/${user ? user?.id : ''}/unitytenants`,
    {},
    !!!user?.id
  );

  const [{ data: languages }] = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `language`,
    {}
  );

  const [{ data: defaultProductList }] = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `products`,
    {}
  );

  const enableFilter = () => {
    if (defaultProductList != null) {
      let matchingProduct = defaultProductList.filter(prod => prod.productId === parseInt(process.env.REACT_APP_UNITY_PRODUCT_ID));
      if (matchingProduct[0].isSubscribable) {
        return filterTenants(userTenants);
      }
    }
    return userTenants;
  }

  const filterTenants = (userTenants) => {
    let tenantMap = new Map();
    let containsProduct = [];

    if (userTenants != null) {
      userTenants.forEach(tenant => tenantMap.set(tenant.tenantId, tenant.products));
    }
    tenantMap.forEach((value, key) => {
      let productArray = []
      value.forEach(product => productArray.push(product.productId))
      if (productArray.includes(parseInt(process.env.REACT_APP_UNITY_PRODUCT_ID))) {
        containsProduct.push({ tenantId: key });
      }
    })
    if (userTenants != null) {
      const myFilteredArray = userTenants.filter((original) => {
        return containsProduct.some(ft => {
          return ft.tenantId === original.tenantId;
        })
      })
      return myFilteredArray;
    }
  }

  useEffect(() => {
    setRenderTenant(currentTenant);
  }, [user, setRenderTenant, userTenants, currentTenant]);

  const userHasAccessToTenant = useMemo(() => {
    if (!getStoredTenant()) {
      if (user?.tenantIds?.includes(user?.currentTenantId)) {
        return true;
      }
      return false;
    } else {
      if (user?.tenantIds?.includes(getStoredTenant())) {
        return true;
      }
      return false;
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && user?.currentTenantId) {
      if (!IS_LOCAL_AUTH && !user?.accessToken) {
        return;
      }
      refetchCurrentTenant().then(tenant => {
        const currentTenantTheme = tenant.data.theme;
        if (!user?.tenantIds.length) {
          setZeroTenantAccess(true);
          theme.setCurrentTheme(null);
        } else {
          if (!currentTenantTheme) {
            theme.setCurrentTheme(null);
          }
          if (theme.setCurrentTheme && currentTenantTheme !== undefined) {
            theme.setCurrentTheme(currentTenantTheme);
          }
        }
      });
    }
    // Don't need to include Theme as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refetchCurrentTenant, isAuthenticated]);

  if (
    (!theme.isInitialized && isAuthenticated && !getTenantError) ||
    (isAuthenticated && user?.statusCode !== 200)
  ) {
    return <LoadingIndicator />;
  }

  if (!IS_LOCAL_AUTH && zeroTenantAccess) {
    return <ZeroTenantAccessDialog open={true} />;
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <SnackbarProvider>
        <Shell
          userAvatar={
            <UserWrapper
              tenant={{
                label: 'Current Tenant',
                current: userHasAccessToTenant ? renderTenant : null,
                options: enableFilter(),
                onSelect: u => {
                  setUser(u);
                },
              }}
              unityUrl={process.env.REACT_APP_UNITY_URL}
              user={user}
            />
          }
          appContent={<AppBarControls />}
          leftMenu={userHasAccessToTenant ? leftMenu : []}
          appSelectorMenus={appSelectorMenus}
          navRoot={'Home'}
          siteName="Tracer"
          useMockData={useMockData}
          mockMenus={mockMenus}
          unityUrl={process.env.REACT_APP_UNITY_URL}
          hideLayout={!isAuthenticated && IS_LOCAL_AUTH}
          languages={languages}
        >
          <ErrorBoundary>
            <Suspense fallback={<PageLoading />}>
              <Switch>
                <Route exact path="/aad_callback" component={AadCallback} />
                {/* If you have different types of login based on .env */}
                {!isAuthenticated && IS_LOCAL_AUTH && (
                  <Route path="/login" exact component={LocalLogin} />
                )}
                <Redirect exact from="/" to="/entries" />
                <PrivateRoute path="/entries" exact component={Entries} />
                <PrivateRoute path="/summary" exact component={Summary} />
                <PrivateRoute path="*" component={PageNotFound} />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </Shell>
      </SnackbarProvider>
    </Router>
  );
};
export default Routes;
