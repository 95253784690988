import { useState } from 'react';

export const verticalPrimary = vertical => {
  if (vertical === 'manage') {
    return '#2951B8';
  } else if (vertical === 'engage') {
    return '#9A1A5F';
  } else if (vertical === 'play') {
    return '#008383';
  } else if (vertical === 'build') {
    return '#CE4A00';
  } else if (vertical === 'unity') {
    return '#0C2F50';
  } else if (vertical === 'sap') {
    return '#344861';
  } else {
    return '#161616';
  }
};

export const verticalSecondary = vertical => {
  if (vertical === 'manage') {
    return '#09A58C';
  } else if (vertical === 'engage') {
    return '#322514';
  } else if (vertical === 'play') {
    return '#500472';
  } else if (vertical === 'build') {
    return '#bf0d10';
  } else if (vertical === 'unity') {
    return '#03BBC3';
  } else if (vertical === 'sap') {
    return '#9eb0c2';
  } else {
    return '#0f62fe';
  }
};

export const useTypeScale = () => {
  const [h1] = useState({
    size: '4rem',
    weight: 600,
    lineHeight: '4.5rem',
    letterSpacing: -1.5,
  });
  const [h2] = useState({
    size: '2.625rem',
    weight: 300,
    lineHeight: 1,
    letterSpacing: -0.5,
  });
  const [h3] = useState({
    size: '2rem',
    weight: 400,
    lineHeight: '2.5rem',
    letterSpacing: 0,
  });
  const [h4] = useState({
    size: '1.5rem',
    weight: 400,
    lineHeight: '2rem',
    letterSpacing: 0.25,
  });
  const [h5] = useState({
    size: '1.375rem',
    weight: 400,
    lineHeight: '1.75rem',
    letterSpacing: 0,
  });
  const [h6] = useState({
    size: '1.5rem',
    weight: 600,
    lineHeight: '1.5rem',
    letterSpacing: 0.15,
  });
  const [subtitle1] = useState({
    size: '1rem',
    weight: 400,
    lineHeight: '1.5rem',
    letterSpacing: 0.15,
  });

  const [subtitle2] = useState({
    size: '0.875rem',
    weight: 600,
    lineHeight: '1.25rem',
    letterSpacing: 0.1,
  });

  const [body1] = useState({
    size: '0.875rem',
    weight: 400,
    lineHeight: '1.5rem',
    letterSpacing: 0.5,
  });

  const [body2] = useState({
    size: '1rem',
    weight: 400,
    lineHeight: '1.5rem',
    letterSpacing: 0.25,
  });

  const [button] = useState({
    size: '0.875rem',
    weight: 600,
    lineHeight: 1,
    letterSpacing: 0.15,
  });

  const [caption] = useState({
    size: '0.75rem',
    weight: 600,
    lineHeight: 1,
    letterSpacing: 0.4,
  });

  const [overline] = useState({
    size: '0.625rem',
    weight: 400,
    lineHeight: 1,
    letterSpacing: 1.5,
  });

  return [
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body1,
    body2,
    subtitle1,
    subtitle2,
    button,
    caption,
    overline,
  ];
};

export const greyScale = shade => {
  let grey;

  if (shade === 'light') {
    grey = '#f6f6f6';
  } else if (shade === 'main') {
    grey = '#eeeeee';
  } else {
    grey = '#262626';
  }
  return grey;
};
